/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($, _c, undefined) {
    "use strict";

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    var Sage = {
        // All pages
        common: {
            init: function() {
                // JavaScript to be fired on all pages

                if ($(".plax").length > 0) {
                    $(window).on("scroll", function() {
                        var scrolled = $(window).scrollTop();
                        $(".plax").css(
                            "transform",
                            "translate3d(0, " + scrolled * -0.2 + "px, 0)"
                        );
                    });
                }

                // Scroll To
                (function() {
                    $(".scrollto").on("click", function(e) {
                        e.preventDefault();
                        var section = $(this).attr("href");
                        $("html, body").animate(
                            {
                                scrollTop: $(section).offset().top
                            },
                            1000
                        );
                    });
                })();

                // Form
                (function() {
                    if (!$("body").hasClass("woocommerce-page")) {
                        $("select").selectric();
                    }
                })();

                (function() {
                    var item = $(".invisible");
                    for (var i = 0; i < item.length; i++) {
                        new Waypoint({
                            element: item[i],
                            handler: function(direction) {
                                if (direction === "down") {
                                    $(this.element).addClass(
                                        "animated-slower fadeIn"
                                    );
                                }
                            },
                            offset: "85%"
                        });
                    }
                })();

                (function() {
                    $("header .mh-btn:not(.cart-customlocation)").click(
                        function(e) {
                            e.preventDefault();
                            $(".book-now-popup").removeClass("hide");
                            $("html, body").addClass("popup-open");
                        }
                    );
                    $(".close-popup").click(function(e) {
                        e.preventDefault();
                        $(".book-now-popup").addClass("hide");
                        $("html, body").removeClass("popup-open");
                    });
                })();

                (function() {
                    $(".talk-now-btn").click(function(e) {
                        e.preventDefault();
                        $(".talk-now-popup").removeClass("hide");
                        $("html, body").addClass("popup-open");
                    });
                    $(".close-popup").click(function(e) {
                        e.preventDefault();
                        $(".talk-now-popup").addClass("hide");
                        $("html, body").removeClass("popup-open");
                    });
                })();

                (function() {
                    if ($("body section").hasClass("fourohfour")) {
                        $("header svg #Homepage").css("fill", "white");
                    }
                })();

                var iOS =
                    !!navigator.platform &&
                    /iPad|iPhone|iPod/.test(navigator.platform);

                if (iOS === true) {
                    $("header").addClass("ios");
                    $("main").addClass("ios");
                    $(".global-menu__item--demo-3").addClass("ios");
                }
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Home page
        page_template_template_home: {
            init: function() {
                function debounce(func, wait, immediate) {
                    var timeout;
                    return function() {
                        var context = this,
                            args = arguments;
                        var later = function() {
                            timeout = null;
                            if (!immediate) func.apply(context, args);
                        };
                        var callNow = immediate && !timeout;
                        clearTimeout(timeout);
                        timeout = setTimeout(later, wait);
                        if (callNow) func.apply(context, args);
                    };
                }

                function initialDraw() {
                    var initialDraw = document.querySelector("#initial-draw");
                    var initialDrawLength = initialDraw.getTotalLength();
                    $(".initial-draw-path")
                        .css("strokeDasharray", initialDrawLength)
                        .css("strokeDashoffset", initialDrawLength);
                }

                function xMarksTheSpot() {
                    var xFirst = $(".x-first");
                    for (var i = 0; i < xFirst.length; i++) {
                        var tl = xFirst[i].getTotalLength();
                        xFirst[i].style.strokeDasharray = tl;
                        xFirst[i].style.strokeDashoffset = tl;
                    }
                    var xSecond = $(".x-second");
                    for (var i = 0; i < xSecond.length; i++) {
                        var tl = xSecond[i].getTotalLength();
                        xSecond[i].style.strokeDasharray = tl;
                        xSecond[i].style.strokeDashoffset = tl;
                    }
                }

                function drawX(l) {
                    l.find(".x-first").addClass("x1-marks-the-spot");
                    l.find(".x-second").addClass("x2-marks-the-spot");
                }

                function indianaJones() {
                    var path = document.querySelector("#indys-airplane");
                    var pathLength = path.getTotalLength();
                    var theHeight =
                        $("#hero").height() +
                        $("#mh-scrollmagic").height() +
                        $("#paper").height() / 1.5;
                    path.style.strokeDasharray = pathLength + " " + pathLength;
                    path.style.strokeDashoffset = pathLength;
                    path.getBoundingClientRect();
                    window.addEventListener("scroll", function(e) {
                        var scrollPercentage =
                            (document.documentElement.scrollTop +
                                document.body.scrollTop) /
                            (document.documentElement.scrollHeight - theHeight);
                        var drawLength = pathLength * scrollPercentage;
                        path.style.strokeDashoffset = pathLength - drawLength;
                        if (scrollPercentage >= 0.99) {
                            path.style.strokeDasharray = "none";
                        } else {
                            path.style.strokeDasharray =
                                pathLength + " " + pathLength;
                        }
                    });
                }

                function templeOfDoom() {
                    var mask = $(".mask");
                    $(window).on("scroll", function() {
                        var scrollTop = window.scrollY;
                        var sectionTop = $("#paper").offset().top - 400;
                        var offset = scrollTop - sectionTop;
                        var draw = 4509 - offset * 1.45;
                        if (scrollTop > sectionTop) {
                            $(mask).css("stroke-dashoffset", draw);
                        }
                    });
                }

                function moveBg() {
                    $(window).on("scroll", function() {
                        var path = document.querySelector("#indys-airplane");
                        var domRect = path.getBoundingClientRect();
                        $("#mh-scrollmagic .absolute").css(
                            "transform",
                            "scale(1) translate3d(0px," +
                                domRect.y * 0.25 +
                                "px, 0)"
                        );
                    });
                }

                function transformReset() {
                    $(".zoom").css("transform", "scale(1) translateX(0)");
                }

                function oddEnter(scale, percentage) {
                    $(".zoom").css(
                        "transform",
                        "scale(" +
                            scale +
                            ") translateX(-" +
                            percentage +
                            "%" +
                            ")"
                    );
                }

                function evenEnter(scale, percentage) {
                    $(".zoom").css(
                        "transform",
                        "scale(" +
                            scale +
                            ") translateX(" +
                            percentage +
                            "%" +
                            ")"
                    );
                }

                var paperTop = $("#paper #top");
                var paperClassTop = $(".paper-plax-top");
                var paperBtm = $("#paper #bottom");
                var paperClassBtm = $(".paper-plax-btm");

                function textPlax(section, targetClass) {
                    if ($(targetClass).length > 0) {
                        $(window).on("scroll", function() {
                            var scrollTop = window.scrollY;
                            var sectionTop = $(section).offset().top;
                            var offset = scrollTop - sectionTop;
                            if (scrollTop > sectionTop - 750) {
                                $(targetClass).css(
                                    "transform",
                                    "translate3d(0, " +
                                        offset * -0.21 +
                                        "px, 0)"
                                );
                            }
                        });
                    }
                }

                var mountains = $("#mountain-panels");
                var blurSection = $("#blur-scroll");
                var blurHeight =
                    $(".mountain-panel-one").outerHeight() +
                    $(".mountain-panel-two").outerHeight();

                function blur(targetClass, blurThis) {
                    if ($(targetClass).length > 0) {
                        $(blurSection).css("height", blurHeight);
                        $(window).on("scroll", function() {
                            var scrollTop = window.scrollY;
                            var sectionTop = $(targetClass).offset().top;
                            var offset = scrollTop - sectionTop;
                            if (scrollTop > sectionTop - 750) {
                                $(blurThis).css(
                                    "filter",
                                    "blur(" + offset / 250 + "px)"
                                );
                            }
                        });
                    }
                }

                // waypoints
                var coNatlEnter = new Waypoint({
                    element: document.querySelector(
                        "#colorado-national .address"
                    ),
                    handler: function(direction) {
                        if (direction == "down") {
                            drawX($("#x-colorado-national"));
                            $(this.element).addClass("show");
                        }
                    },
                    offset: "84.5%"
                });

                var riverdaleEnter = new Waypoint({
                    element: document.querySelector("#riverdale .address"),
                    handler: function(direction) {
                        if (direction == "down") {
                            drawX($("#x-riverdale"));
                            $(this.element).addClass("show");
                        }
                    },
                    offset: "70%"
                });
                var pcEnter = new Waypoint({
                    element: document.querySelector("#plum-creek .address"),
                    handler: function(direction) {
                        if (direction == "down") {
                            drawX($("#x-plum-creek"));
                            $(this.element).addClass("show");
                        }
                    },
                    offset: "60%"
                });
                var bdEnter = new Waypoint({
                    element: document.querySelector("#bear-dance .address"),
                    handler: function(direction) {
                        if (direction == "down") {
                            drawX($("#x-bear-dance"));
                            $(this.element).addClass("show");
                        }
                    },
                    offset: "50%"
                });

                var xLatestNews = new Waypoint({
                    element: document.getElementById("x-latest-news"),
                    handler: function(direction) {
                        if (direction == "down") {
                            drawX($(this.element));
                        }
                    },
                    offset: "25%"
                });

                var controller = new ScrollMagic.Controller();
                var scene = new ScrollMagic.Scene({
                    triggerElement: "#blur-scroll",
                    triggerHook: 0,
                    duration: $("#mountain-panels").height()
                    // pushFollowers: false
                })
                    .setPin("#blur-scroll")
                    .addTo(controller);

                var mySwiper = new Swiper(".swiper-container", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 2.5,
                    spaceBetween: 40,
                    // setWrapperSize: true,
                    // autoHeight: true,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    },
                    breakpoints: {
                        580: {
                            slidesPerView: 1
                        }
                    }
                });

                $(window).on("resize load", function() {
                    // mySwiper.update();
                    if ($(window).width() <= 568) {
                        var newHeight = $(".swiper-slide").height();
                        $("#mountain-panels").css(
                            "min-height",
                            blurHeight + newHeight - 275
                        );
                        mySwiper.destroy();
                        $(".mountain-panel-two").css(
                            "top",
                            $(".mountain-panel-one").outerHeight() + 100
                        );
                    } else {
                        $(mountains).css("height", blurHeight);
                        $(".mountain-panel-two").css(
                            "top",
                            $(".mountain-panel-one").outerHeight() + 200
                        );
                        // mySwiper.reInit();
                    }
                });

                var media = document.querySelector("video");
                var play = document.querySelector(".play-btn a");
                function playPauseMedia(e) {
                    e.preventDefault();
                    if (media.paused) {
                        media.play();
                    } else {
                        media.pause();
                    }
                }
                play.addEventListener("click", playPauseMedia);

                initialDraw();
                debounce(indianaJones(), 150);
                xMarksTheSpot();
                textPlax(paperTop, paperClassTop);
                textPlax(paperBtm, paperClassBtm);
                debounce(blur(mountains, blurSection), 250);
                var iOS =
                    !!navigator.platform &&
                    /iPad|iPhone|iPod/.test(navigator.platform);

                if (iOS === false) {
                    moveBg();
                    debounce(templeOfDoom(), 150);
                }
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        page_template_template_trail_courses: {
            init: function() {
                var courses = $(".trail-courses .course");
                var rightCourses = $(".right-course svg");

                $(courses).each(function(ind, val) {
                    $(this).on("mouseenter", function() {
                        $(rightCourses)
                            .eq(ind)
                            .siblings(".info")
                            .addClass("hovered");
                        $(rightCourses)
                            .eq(ind)
                            .siblings(".info")
                            .prev()
                            .addClass("white");
                    });
                    $(this).on("mouseleave", function() {
                        $(rightCourses)
                            .eq(ind)
                            .siblings(".info")
                            .removeClass("hovered");
                        $(rightCourses)
                            .eq(ind)
                            .siblings(".info")
                            .prev()
                            .removeClass("white");
                    });
                });
                $(rightCourses).each(function(ind, val) {
                    $(this).on("mouseenter", function() {
                        $(this)
                            .siblings(".info")
                            .addClass("hovered");
                        $(courses)
                            .eq(ind)
                            .addClass("hovered");
                        $(this).addClass("white");
                    });
                    $(this).on("mouseleave", function() {
                        $(this)
                            .siblings(".info")
                            .removeClass("hovered");
                        $(courses)
                            .eq(ind)
                            .removeClass("hovered");
                        $(this).removeClass("white");
                    });
                });
            }
        },
        page_template_template_careers: {
            init: function() {
                var outer = new Waypoint({
                    element: document.getElementById("outer-ring"),
                    handler: function() {
                        $(".outer-mask").addClass("animate");
                        $(".middle-mask").addClass("animate");
                        $(".inner-mask").addClass("animate");
                    },
                    offset: "60%"
                });
            }
        },
        page_template_template_single: {
            init: function() {
                var related = new Waypoint({
                    element: document.querySelector(".related"),
                    handler: function() {
                        $(".outer-mask").addClass("animate");
                        $(".middle-mask").addClass("animate");
                    },
                    offset: "50%"
                });
            }
        },
        page_template_template_about: {
            init: function() {
                function lastCrusade() {
                    var mask = $(".about-mask");
                    $(window).on("scroll", function() {
                        var scrollTop = window.scrollY;
                        var sectionTop = $("#tmb").offset().top - 350;
                        var offset = scrollTop - sectionTop;
                        var draw = 4193 - offset * 1.55;
                        if (scrollTop > sectionTop) {
                            $(mask).css("stroke-dashoffset", draw);
                        }
                    });
                }
                var paperTop = $(".paper .top");
                var paperClassTop = $(".paper-plax-top");
                var paperMid = $(".paper .middle");
                var paperClassMid = $(".paper-plax-middle");
                var paperBtm = $(".paper .bottom");
                var paperClassBtm = $(".paper-plax-bottom");

                function textPlax(section, targetClass) {
                    if ($(targetClass).length > 0) {
                        $(window).on("scroll", function() {
                            var scrollTop = window.scrollY;
                            var sectionTop = $(section).offset().top;
                            var offset = scrollTop - sectionTop;
                            if (scrollTop > sectionTop - 750) {
                                $(targetClass).css(
                                    "transform",
                                    "translate3d(0, " +
                                        offset * -0.21 +
                                        "px, 0)"
                                );
                            }
                        });
                    }
                }
                var middleSlider = new Swiper("#middle-slider", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        nextEl: ".middle .swiper-button-next"
                    },
                    breakpoints: {
                        1023: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2
                        },
                        580: {
                            slidesPerView: 1
                        }
                    }
                });
                var btmSlider = new Swiper("#bottom-slider", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        nextEl: ".bottom .swiper-button-next"
                    },
                    breakpoints: {
                        1023: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2
                        },
                        580: {
                            slidesPerView: 1
                        }
                    }
                });
                $(window).on("resize", function() {
                    middleSlider.update();
                    btmSlider.update();
                });
                lastCrusade();
                var iOS =
                    !!navigator.platform &&
                    /iPad|iPhone|iPod/.test(navigator.platform);

                if (iOS === false) {
                    lastCrusade();
                }
                textPlax(paperTop, paperClassTop);
                textPlax(paperMid, paperClassMid);
                textPlax(paperBtm, paperClassBtm);

                $(".history .dot").on("click", function(event) {
                    event.preventDefault();
                    var index = $(this).data("index");
                    $(this)
                        .siblings("h2")
                        .fadeOut(300);
                    $(".history .dot").fadeOut(300);
                    $(".year-popup").fadeOut(300);
                    $(".year-" + index).fadeIn(300);
                });
                $(".year-popup .prev").on("click", function(event) {
                    event.preventDefault();
                    var index = $(this).data("index");
                    $(".year-popup").fadeOut(300);
                    $(".year-" + index).fadeIn(300);
                });
                $(".year-popup .next").on("click", function(event) {
                    event.preventDefault();
                    var index = $(this).data("index");
                    $(".year-popup").fadeOut(300);
                    $(".year-" + index).fadeIn(300);
                });
                $(".year-popup .close").on("click", function(event) {
                    event.preventDefault();
                    $(".year-popup").fadeOut(300);
                    $(".history .dot").fadeIn(300);
                    $(".history h2").fadeIn(300);
                });
            }
        },
        page_template_template_events: {
            init: function() {
                var host =
                    location.protocol +
                    "//" +
                    location.hostname +
                    "/wp-json/acf/v3/events?per_page=100";
                function getEvents() {
                    $.ajax({
                        url: host,
                        method: "GET",
                        dataType: "json"
                    })
                        .done(function(data) {
                            var date = new Date();
                            var d = date.getDate();
                            var m = date.getMonth();
                            var y = date.getFullYear();
                            var day_was_clicked = false;
                            var dayClicked;

                            $("#loading").css("display", "none");

                            var newEvents = [];
                            var len = data.length;
                            for (var i = 0; i < len; i++) {
                                newEvents.push({
                                    title: data[i].acf.e_name,
                                    start: new Date(data[i].acf.e_start),
                                    end: new Date(data[i].acf.e_end),
                                    thumb: data[i].acf.e_image,
                                    location: data[i].acf.e_location,
                                    type: data[i].acf.e_type,
                                    className: data[i].acf.e_type,
                                    desc: data[i].acf.e_desc,
                                    url:
                                        location.protocol +
                                        "//" +
                                        location.hostname +
                                        "/" +
                                        data[i].acf.e_slug
                                });
                            }

                            // Event Calendar
                            var $calendar = $("#calendar");
                            $calendar.fullCalendar({
                                header: {
                                    left: "",
                                    center: "prev,title,next",
                                    right: "listMonth,month"
                                },
                                weekends: true,
                                allDaySlot: true,
                                droppable: true,
                                events: newEvents,
                                titleFormat: "MMMM YYYY",
                                columnFormat: "dddd",
                                timeFormat: "ht",
                                eventAfterAllRender: function(view) {
                                    // Set View
                                    var currentView = $(
                                        "#calendar"
                                    ).fullCalendar("getView").name;
                                    if (currentView == "listMonth") {
                                        $("#calendar")
                                            .parent()
                                            .parent()
                                            .removeClass("month-view");
                                        $("#calendar")
                                            .parent()
                                            .parent()
                                            .addClass("list-view");
                                        var month_view_date = $(
                                            "#calendar"
                                        ).fullCalendar("getDate");
                                    }
                                    if (currentView == "month") {
                                        $("#calendar")
                                            .parent()
                                            .parent()
                                            .addClass("month-view");
                                        $("#calendar")
                                            .parent()
                                            .parent()
                                            .removeClass("list-view");
                                        $(
                                            "#calendar .fc-view-container"
                                        ).show();
                                        $("#calendar").css("height", "auto");
                                        day_was_clicked = false;
                                        dayClicked = "";
                                    }

                                    // Create Header
                                    var header = $calendar.find(
                                        ".fc-header-toolbar"
                                    );

                                    // Insert Header Filerts
                                    if ($("#filters").length < 1) {
                                        var filtersHtml = document.createElement(
                                            "ul"
                                        );
                                        filtersHtml.setAttribute(
                                            "id",
                                            "filters"
                                        );
                                        filtersHtml.setAttribute(
                                            "class",
                                            "event-filters"
                                        );
                                        var filter_contents =
                                            '<li class="location-filter"><label>Location</label> <select id="location-select"><option value="all">All</option><option value="colorado-national">Colorado National</option><option value="riverdale">Riverdale</option><option value="plum-creek">Plum Creek</option><option value="bear-dance">Bear Dance</option></select></li>' +
                                            '<li class="type-filter"><label>Type of Events</label> <select id="type-select"><option value="all">All</option><option value="golf">Golf</option><option value="food-and-beverage">Food & Beverage</option><option value="league">League</option></select></li>';
                                        filtersHtml.innerHTML = filter_contents;
                                        $calendar
                                            .find(".fc-right")
                                            .prepend(filtersHtml);
                                    }
                                    $("select").selectric();
                                    // Trigger Current View
                                    header
                                        .find(".fc-right")
                                        .find(".fc-button")
                                        .off("mouseup")
                                        .on("mouseup", function() {
                                            if (
                                                !$(this).hasClass(
                                                    "fc-listMonth-button"
                                                )
                                            ) {
                                                $calendar.data("view", "");
                                            }
                                        });
                                    if (
                                        $("#calendar").fullCalendar("getView")
                                            .name != "listMonth"
                                    ) {
                                        header
                                            .find(".fc-listMonth-button")
                                            .removeClass(
                                                "fc-state-active active"
                                            );
                                        $(".list-events").html("");
                                    } else {
                                        renderListView(
                                            day_was_clicked,
                                            dayClicked,
                                            month_view_date
                                        );
                                    }
                                },
                                eventRender: function(event, e) {
                                    var str = event.location;
                                    str = str
                                        .replace(/\s+/g, "-")
                                        .toLowerCase();
                                    $(e).addClass(event.category);
                                    $(e).addClass(str);
                                    var ntoday = new Date().getTime();
                                    var eventStart = moment(
                                        event.start
                                    ).valueOf();
                                    var eventEnd = moment(event.end).valueOf();

                                    if (!event.end) {
                                        if (eventStart < ntoday) {
                                            e.addClass("past-event");
                                            e.children().addClass("past-event");
                                        }
                                    } else {
                                        if (eventEnd < ntoday) {
                                            e.addClass("past-event");
                                            e.children().addClass("past-event");
                                        }
                                    }
                                },
                                dayClick: function(date, jsEvent, view) {},
                                eventClick: function(calEvent, jsEvent, view) {}
                            });

                            // Render Calendar onload
                            $(window).on("load", function() {
                                $("#calendar").fullCalendar("rerenderEvents");
                            });

                            // Render List View
                            function renderListView(
                                day_was_clicked,
                                dayClicked,
                                month_view_date
                            ) {
                                var event_contents = '<div class="row">';
                                // Todays Date
                                var ntoday = new Date().getTime();
                                // Events
                                for (var key in newEvents) {
                                    var eventStart = moment(
                                        newEvents[key].start
                                    ).valueOf();
                                    var eventEnd = moment(
                                        newEvents[key].end
                                    ).valueOf();
                                    var eventStartDate = moment(
                                        newEvents[key].start
                                    ).format("MMMM DD, h:mmA");
                                    var eventDataDate = moment(
                                        newEvents[key].start
                                    );
                                    eventDataDate = eventDataDate
                                        .add(1, "day")
                                        .format("YYYY-MM-DD");
                                    var checkEvent = moment(
                                        eventDataDate
                                    ).format("YYYY-MM-DD");
                                    var monthStartDate = moment(
                                        month_view_date
                                    ).format("YYYY-MM-[01]");
                                    var monthEndDate = moment(month_view_date)
                                        // .format("YYYY-MM-[01]")
                                        .endOf("month")
                                        .format("YYYY-MM-DD");

                                    var eventEndDate = moment(
                                        newEvents[key].end
                                    ).format("MMMM DD, h:mmA");
                                    var eventTitle = newEvents[key].title;
                                    var eventExcerpt = newEvents[key].desc;
                                    var eventType = newEvents[key].type;
                                    var eventLocation = newEvents[key].location;
                                    var eventThumb = newEvents[key].thumb;
                                    var eventUrl = newEvents[key].url;
                                    var listLocation = newEvents[key].location;
                                    listLocation = listLocation
                                        .replace(/\s+/g, "-")
                                        .toLowerCase();
                                    // check if it is current event
                                    if (
                                        eventStart > ntoday &&
                                        eventEnd > ntoday
                                    ) {
                                        if (month_view_date) {
                                            if (
                                                checkEvent > monthStartDate &&
                                                checkEvent < monthEndDate
                                            ) {
                                                // console.log(monthDate);
                                                event_contents +=
                                                    '<div class="event ' +
                                                    listLocation +
                                                    " col-xs-4 " +
                                                    eventType +
                                                    '" data-date="' +
                                                    eventDataDate +
                                                    '" data-category="all ' +
                                                    eventType +
                                                    '">' +
                                                    '<div class="thumb-wrap"><div class="thumb" style="background:url(' +
                                                    eventThumb +
                                                    ') 50% no-repeat;"><a href="' +
                                                    eventUrl +
                                                    '"></a></div></div>' +
                                                    '<div class="cnt">' +
                                                    '<p class="event-title"><a href="' +
                                                    eventUrl +
                                                    '" class="body-link"><span>' +
                                                    eventTitle +
                                                    "</span></a></p>" +
                                                    '<p class="location">' +
                                                    eventLocation +
                                                    "</p>" +
                                                    '<p class="date">' +
                                                    eventStartDate +
                                                    " to " +
                                                    eventEndDate +
                                                    "</p>" +
                                                    '<div class="excerpt">' +
                                                    "<p>" +
                                                    eventExcerpt +
                                                    "</p>" +
                                                    "</div>" +
                                                    '<a href="' +
                                                    eventUrl +
                                                    '" class="more-btn">Read More</a>' +
                                                    "</div>" +
                                                    "</div>";
                                            }
                                        }
                                    }
                                }
                                event_contents += "</div>";
                                $("#calendar .fc-view-container").hide();
                                $(".list-events").html(event_contents);
                                // No Events
                                if ($("#calendar").hasClass("list-view")) {
                                    if ($(".list-events .event").length < 1) {
                                        $(".no-events").show();
                                    } else {
                                        $(".no-events").hide();
                                    }
                                }
                            }

                            if (
                                window.matchMedia("(max-width: 767px)").matches
                            ) {
                                setTimeout(function() {
                                    $(".fc-listMonth-button").trigger("click");
                                }, 100);
                            }
                            $(window).on("load resize", function() {
                                if (
                                    window.matchMedia("(max-width: 767px)")
                                        .matches
                                ) {
                                    setTimeout(function() {
                                        $(".fc-listMonth-button").trigger(
                                            "click"
                                        );
                                    }, 100);
                                }
                            });
                            $("#calendar #type-select").on(
                                "change",
                                function() {
                                    var theValue = $(this).val();
                                    var location = $(
                                        "#calendar #location-select"
                                    ).val();

                                    if (
                                        theValue === "all" &&
                                        location === "all"
                                    ) {
                                        $("#calendar a").show();
                                        $(".list-events .event").show();
                                    }

                                    if (
                                        theValue === "all" &&
                                        location !== "all"
                                    ) {
                                        $("#calendar a")
                                            .not("a." + location)
                                            .hide();
                                        $(".list-events .event")
                                            .not(".event." + location)
                                            .hide();

                                        $("a." + location).show();
                                        $(".event." + location).show();
                                    }

                                    if (
                                        theValue !== "all" &&
                                        location === "all"
                                    ) {
                                        $("#calendar a")
                                            .not("a." + theValue)
                                            .hide();
                                        $(".list-events .event")
                                            .not(".event." + theValue)
                                            .hide();

                                        $("a." + theValue).show();
                                        $(".event." + theValue).show();
                                    }

                                    if (
                                        theValue !== "all" &&
                                        location !== "all"
                                    ) {
                                        $("#calendar a")
                                            .not(
                                                "a." + theValue + "." + location
                                            )
                                            .hide();
                                        $(".list-events .event")
                                            .not(
                                                ".event." +
                                                    theValue +
                                                    "." +
                                                    location
                                            )
                                            .hide();

                                        $(
                                            "a." + theValue + "." + location
                                        ).show();
                                        $(
                                            ".event." +
                                                theValue +
                                                "." +
                                                location
                                        ).show();
                                    }
                                }
                            );
                            $("#calendar #location-select").on(
                                "change",
                                function() {
                                    var locationValue = $(this).val();
                                    var value = $(
                                        "#calendar #type-select"
                                    ).val();

                                    if (
                                        locationValue === "all" &&
                                        value === "all"
                                    ) {
                                        $("#calendar a").show();
                                        $(".list-events .event").show();
                                    }

                                    if (
                                        locationValue === "all" &&
                                        value !== "all"
                                    ) {
                                        $("#calendar a")
                                            .not("a." + value)
                                            .hide();
                                        $(".list-events .event")
                                            .not(".event." + value)
                                            .hide();

                                        $("a." + value).show();
                                        $(".event." + value).show();
                                    }

                                    if (
                                        locationValue !== "all" &&
                                        value === "all"
                                    ) {
                                        $("#calendar a")
                                            .not("a." + locationValue)
                                            .hide();
                                        $(".list-events .event")
                                            .not(".event." + locationValue)
                                            .hide();

                                        $("a." + locationValue).show();
                                        $(".event." + locationValue).show();
                                    }

                                    if (
                                        locationValue !== "all" &&
                                        value !== "all"
                                    ) {
                                        $("#calendar a")
                                            .not(
                                                "a." +
                                                    locationValue +
                                                    "." +
                                                    value
                                            )
                                            .hide();
                                        $(".list-events .event")
                                            .not(
                                                ".event." +
                                                    locationValue +
                                                    "." +
                                                    value
                                            )
                                            .hide();

                                        $(
                                            "a." + locationValue + "." + value
                                        ).show();
                                        $(
                                            ".event." +
                                                locationValue +
                                                "." +
                                                value
                                        ).show();
                                    }
                                }
                            );
                        })
                        .fail(function(xhr) {
                            console.log("error", xhr);
                        });
                }
                getEvents();
            },
            finalize: function() {}
        },
        single_events: {
            init: function() {}
        },
        page_template_template_groups: {
            init: function() {
                var paperTop = $(".paper .top");
                var paperClassTop = $(".paper-plax-top");
                var paperMid = $(".paper .middle");
                var paperClassMid = $(".paper-plax-middle");

                function textPlax(section, targetClass) {
                    if ($(targetClass).length > 0) {
                        $(window).on("scroll", function() {
                            var scrollTop = window.scrollY;
                            var sectionTop = $(section).offset().top;
                            var offset = scrollTop - sectionTop;
                            if (scrollTop > sectionTop - 750) {
                                $(targetClass).css(
                                    "transform",
                                    "translate3d(0, " +
                                        offset * -0.21 +
                                        "px, 0)"
                                );
                            }
                        });
                    }
                }

                var groupsSlider1 = new Swiper("#groups-slider1", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        nextEl: "#groups-slider1 .swiper-button-next"
                    },
                    breakpoints: {
                        1023: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2
                        },
                        580: {
                            slidesPerView: 1
                        }
                    }
                });
                var groupsSlider2 = new Swiper("#groups-slider2", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                    loopAdditionalSlides: 1,
                    slidesOffsetBefore: 260,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        prevEl: "#groups-slider2 .swiper-button-next"
                    },
                    breakpoints: {
                        1550: {
                            slidesOffsetBefore: 240
                        },
                        1400: {
                            slidesOffsetBefore: 220
                        },
                        1280: {
                            slidesOffsetBefore: 200
                        },
                        1180: {
                            slidesOffsetBefore: 180
                        },
                        1080: {
                            slidesOffsetBefore: 150
                        },
                        1023: {
                            slidesPerView: 3,
                            slidesOffsetBefore: 0
                        },
                        768: {
                            slidesPerView: 2,
                            slidesOffsetBefore: 0
                        },
                        580: {
                            slidesPerView: 1,
                            slidesOffsetBefore: 0
                        }
                    }
                });
                var groupsSlider3 = new Swiper("#groups-slider3", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        nextEl: "#groups-slider3 .swiper-button-next"
                    },
                    breakpoints: {
                        1023: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2
                        },
                        580: {
                            slidesPerView: 1
                        }
                    }
                });
                var groupsSlider4 = new Swiper("#groups-slider4", {
                    speed: 1000,
                    direction: "horizontal",
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                    loopAdditionalSlides: 1,
                    slidesOffsetBefore: 260,
                    slideToClickedSlide: true,
                    loop: true,
                    navigation: {
                        prevEl: "#groups-slider4 .swiper-button-next"
                    },
                    breakpoints: {
                        1550: {
                            slidesOffsetBefore: 240
                        },
                        1400: {
                            slidesOffsetBefore: 220
                        },
                        1280: {
                            slidesOffsetBefore: 200
                        },
                        1180: {
                            slidesOffsetBefore: 180
                        },
                        1080: {
                            slidesOffsetBefore: 150
                        },
                        1023: {
                            slidesPerView: 3,
                            slidesOffsetBefore: 0
                        },
                        768: {
                            slidesPerView: 2,
                            slidesOffsetBefore: 0
                        },
                        580: {
                            slidesPerView: 1,
                            slidesOffsetBefore: 0
                        }
                    }
                });

                textPlax(paperTop, paperClassTop);
            }
        },
        page_template_template_trail_course: {
            init: function() {
                $(".toggle-course").click(function(e) {
                    e.preventDefault();
                    $(".more-than-normal").toggleClass("show");
                    $(".normal").toggleClass("hide");
                });

                // draggable
                function courseDrag() {
                    var dragContainerWidth =
                        $("#viewContainer").innerWidth() +
                        ($("#draggable").outerWidth() -
                            $("#viewContainer").innerWidth()) *
                            2;
                    var dragContainerHeight =
                        $("#viewContainer").innerHeight() +
                        ($("#draggable").outerHeight() -
                            $("#viewContainer").innerHeight()) *
                            2;
                    $("#draggableContainer").css("width", dragContainerWidth);
                    $("#draggableContainer").css("height", dragContainerHeight);
                    var draggableLeftPos = dragContainerWidth / 2;
                    var draggableTopPos = dragContainerHeight / 2;
                    var dragContainerOffsetLeft =
                        $("#viewContainer").offset().left +
                        $("#viewContainer").outerWidth() / 2 +
                        $("#viewContainer").innerWidth() / 2 -
                        $("#draggable").outerWidth();
                    var dragContainerOffsetTop =
                        $("#viewContainer").offset().top +
                        $("#viewContainer").outerHeight() / 2 +
                        $("#viewContainer").innerHeight() / 2 -
                        $("#draggable").outerHeight();
                    $("#draggableContainer").offset({
                        left: dragContainerOffsetLeft,
                        top: dragContainerOffsetTop
                    });
                    var parentPos = $("#draggable").offset();
                    $("#draggable").css({
                        top: draggableTopPos,
                        left: draggableLeftPos
                    });

                    $("#draggable").draggable({
                        containment: "#draggableContainer",
                        scroll: false,
                        drag: function(event, ui) {}
                    });
                }
                courseDrag();

                $(window).on("resize", function() {
                    courseDrag();
                });

                $(".hole a")
                    .on("mouseenter", function() {
                        var hole = $(this).data("popup");
                        $(".course-map .cnt .holes ul li a").removeClass(
                            "active"
                        );
                        $(".hole-link-" + hole).addClass("active");
                    })
                    .on("mouseleave", function() {
                        $(".course-map .cnt .holes ul li a").removeClass(
                            "active"
                        );
                        $(".hole").removeClass("active");
                    });

                $(".course-map .cnt .holes ul li a")
                    .on("mouseenter", function() {
                        var hole = $(this).data("hole");
                        $(".course-map .hole").removeClass("active");
                        $(".course-map .hole-" + hole).addClass("active");
                    })
                    .on("mouseleave", function() {
                        $(".course-map .hole a").removeClass("active");
                        $(".hole").removeClass("active");
                    });

                $(".hole a").on("click", function() {
                    var hole = $(this).data("popup");
                    $(".course-map .popup").removeClass("active");
                    $(".course-map .hole-" + hole + "-popup").addClass(
                        "active"
                    );
                    $(".page-overlay").fadeIn(300);
                });

                // $(".hole a").on("click", function() {
                //     var hole = $(this).data("popup");
                //     $(".course-map .popup").removeClass("active");
                //     $(".course-map .hole-" + hole + "-popup").addClass(
                //         "active"
                //     );
                // });

                $(".course-map .cnt .holes ul li a").on("click", function() {
                    var hole = $(this).data("hole");
                    $(".course-map .popup").removeClass("active");
                    $(".course-map .hole-" + hole + "-popup").addClass(
                        "active"
                    );
                    $(".page-overlay").fadeIn(300);
                });

                $(".course-map .popup .close").on("click", function(event) {
                    event.preventDefault();
                    $(".course-map .popup").removeClass("active");
                    $(".page-overlay").fadeOut(300);
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
